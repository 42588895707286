/* insurance policy category section  */
.category_section {
  margin-top: 40px;
  border-top: 1px solid var(--white);
  box-sizing: border-box;
  margin-bottom: 128px;
}

.category_section > img {
  z-index: -1;
}

.categotry_cards_container,
.policy_action_container {
  width: fit-content;
  /* margin: 0 auto; */
}

.categotry_cards_container {
  margin-top: 60px;
  gap: 24px;
}

.categotry_card {
  width: 188px;
  height: 145px;
  max-width: 188px;
  max-height: 145px;
  background-color: white;
  box-shadow: 1px 1px 3px rgba(28, 36, 42, 0.31);
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  gap: 15px;
  transition: 0.3s ease;
}

.comming_soon.comming_soon::after {
  display: flex;
  content: "Comming soon";
  position: absolute;
  font-size: 15px;
  color: white;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(28, 36, 42, 0.9);
  cursor: not-allowed;
  border-radius: 5px;
  transition: 0.3s ease;
  opacity: 0;
}

.comming_soon:hover.comming_soon::after {
  opacity: 1;
}

.categotry_card:hover:not(.comming_soon) {
  border: 1px solid orange;
  background-color: #ddd;
}

.categotry_icon {
  width: 70px;
  height: 70px;
}

.categotry_text {
  color: #111;
  line-height: 26.4px;
}

.policy_action_container {
  margin-top: 24px;
  gap: 36px;
}

.policy_icons {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.policy_text {
  color: var(--black);
}

.bimabhai_section {
  height: 344px;
  width: 85%;
  background-color: var(--secondary-2);
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 71px;
  margin-bottom: 120px;
}

.bimabhai_image_container {
  width: 40%;
  overflow: hidden;
}

.bimabhai_text_container {
  width: 60%;
}

.bimbhai_image {
  z-index: 99;
  width: 90%;
  height: 90%;
}

.bimabhai_section_text {
  color: #333846;
  width: 80%;
  margin: 0 auto;
}

.bimabhai_section_button {
  margin-top: 22px;
  box-shadow: 0px 8px 8px rgba(243, 70, 83, 0.2);
  border-radius: 8px;
  width: 107px;
  height: 44px;
}

@media only screen and (max-width: 992px) {
  .category_section {
    margin-top: 15px;
  }

  .category_section > img {
    height: 300px;
  }

  .categotry_cards_container {
    margin-top: 80px;
  }

  .categotry_card {
    width: 160px;
    height: 120px;
  }

  .categotry_icon {
    width: 60px;
    height: 60px;
  }

  .categotry_text,
  .policy_text {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .bimbhai_image {
    height: 85%;
  }

  .bimabhai_section {
    height: 224px;
  }

  .bimabhai_section_text > h2 {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .bimabhai_section_button {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 576px) {
  .categotry_cards_container {
    gap: 10px;
    width: 100%;
    align-items: center;
  }

  .categotry_card {
    width: 100%;
  }

  .policy_action_container {
    justify-content: center;
  }

  .category_section {
    margin-top: 0px;
  }

  .category_section > img {
    height: 300px;
  }

  .categotry_icon {
    width: 50px;
    height: 50px;
  }

  .categotry_text {
    font-size: 12px;
  }

  .bimabhai_section_text > h2 {
    font-size: 15px !important;
    line-height: 16px;
  }

  .bimabhai_section {
    width: 100%;
    height: 160px;
  }

  .bimabhai_section_button {
    width: 100px;
    height: 36px;
    margin-top: 5px;
  }

  .bimbhai_image {
    width: 95%;
    height: 75%;
  }
}

@media only screen and (max-width: 300px) {
  .categotry_cards_container {
    gap: 5px;
  }

  .categotry_card {
    height: 100px;
  }

  .policy_text {
    font-size: 12px;
  }

  .categotry_icon {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (min-width: 1600px) {
  .category_section > img {
    height: 40%;
    width: 100%;
  }

  .categotry_cards_container {
    margin-top: 80px;
  }

  .categotry_card {
    width: 260px;
    height: 190px;
    max-width: 260px;
    max-height: 200px;
  }

  .categotry_icon {
    width: 80px;
    height: 80px;
  }

  .policy_text,
  .categotry_text {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
